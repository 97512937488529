/* ------------------------------------------------------------ *\
	Socials
\* ------------------------------------------------------------ */

.socials {
	padding-top: 52px;

	@include breakpoint-down(mobile) {
		padding-top: 34px;
	}

	ul {
		padding-left: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		list-style: none;

		@include breakpoint-down(small-desktop) {
			justify-content: center;
		}
	}

	li + li {
		margin-left: 25px;
	}

	a {
		width: 53px;
		height: 53px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 3px solid $c-brown-light;
		border-radius: 50%;
		color: $c-white !important;
		transition: color .3s;

		&:hover {
			color: $c-brown-light !important;
		}

		@include breakpoint-up(xxl-desktop) {
			@include rfs(4.33rem, width);
			@include rfs(4.33rem, height);
		}
	}
}
