@mixin clearfix {
	content: '';
	line-height: 0;
	display: table;
	clear: both;
}

@mixin breakpoint-up($name, $breakpoints: $breakpoints) {
	$min: breakpoint-min($name, $breakpoints);

	@media (min-width: $min) {
		@content;
	}
}

@mixin breakpoint-down($name, $breakpoints: $breakpoints) {
	$max: breakpoint-max($name, $breakpoints);

	@if $max == 767px {
		@media (max-width: $max), screen and (max-width: $responsive-ios) and (orientation: landscape) {
			@content;
		}
	} @else {
		@media (max-width: $max) {
			@content;
		}
	}
}

@mixin breakpoint-between($lower, $upper, $breakpoints: $breakpoints) {
	$min: breakpoint-min($lower, $breakpoints);
	$max: breakpoint-max($upper, $breakpoints);

	@media (min-width: $min) and (max-width: $max) {
		@content;
	}
}

/* example usage*/

// @include breakpoint-down(mobile) {
//	@content
// }
// @include breakpoint-down(mobile) {
//	@content
// }
// // @include breakpoint-between(mobile,tablet) {
//	@content
// }

@mixin centerBackground {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

@mixin sliderControls {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 74vw;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 2;
}

@mixin sliderButton {
	border: 0;
	text-indent: -4000px;
	font-size: 0;
	line-height: 0;
	@include centerBackground;
	width: 53px;
	height: 53px;
	border-radius: 50%;
	border: 4px solid $c-brown-light;
	background-color: transparent;
	transition: transform $t-duration ease-in-out;

	&:before {
		content: '';
		display: block;
		margin: 0 auto;
		line-height: 0;
		width: 0;
		height: 0;
		border-top: 7px solid transparent;
		border-bottom: 7px solid transparent;
		border-left: none;
		border-right: 7px solid currentColor;
		transition: border-color $t-duration ease-in-out;
	}

	&:hover:before {
		border-right-color: $c-brown-light;
	}

}

@mixin sliderButtonRight {
	&:before {
		border-top: 7px solid transparent;
		border-bottom: 7px solid transparent;
		border-left: 7px solid currentColor;
		border-right: 0;
	}

	&:hover:before {
		border-left-color: $c-brown-light;
	}
}

@mixin sectionLogo {
	font-size: 195px;
	-webkit-text-fill-color: transparent;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #e5dbcf;
	text-transform: uppercase;
	white-space: nowrap;
}
