/* ------------------------------------------------------------ *\
  section-partners
\* ------------------------------------------------------------ */

.section-partners {
  background: $c-white-dark;
  padding: 38px 0 101px;
  overflow: hidden;

  @include breakpoint-down(tablet) {
    padding: 25px 0 60px;
  }

  @include breakpoint-down(mobile) {
    padding: 23px 0 52px;
  }

  .container {
    max-width: 1560px;

    @include breakpoint-up(xxl-desktop) {
      @include rfs(131.9rem ,max-width)
    }
  }

  .section__inner {
		@include breakpoint-down(mobile) {
      overflow-x: auto;
      overflow-y: hidden;
      margin-right: -10px;

		}
  }

  ul {
    margin-right: -23px;
    margin-left: -30px;
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: center;

		@include breakpoint-down(mobile) {
      margin-left: 15px;
      margin-right: 0;
		}
  }

  li {
    padding: 30px;
    min-width: 220px;
    flex: 0 0 25%;
    max-width: 25%;

		@include breakpoint-down(mobile) {
      padding: 5px;
      min-width: 175px;
		}
  }

  a {
    display: block;
    text-align: center;
  }

}
