/* ------------------------------------------------------------ *\
  section-tabs
\* ------------------------------------------------------------ */

.section-tabs {
  padding: 91px 0;
  background-color: $c-white-dark;

  @include breakpoint-down(tablet) {
    padding: 50px 0;
  }

  @include breakpoint-down(mobile) {
    padding: 40px 0 17px;
  }

  .section__head {
    margin-bottom: 83px;

    @include breakpoint-down(tablet) {
      margin-bottom: 60px;
    }

    @include breakpoint-down(mobile) {
      margin-bottom: 45px;
    }
  }

  .section__title {
    text-align: center;
    margin-bottom: 23px;
  }

  .section__entry {
    text-align: center;
    max-width: 627px;
    margin: 0 auto 20px;
    line-height: 1.9;

      &--left{
          text-align: left;
      }

    @include breakpoint-up(xxl-desktop) {
      @include rfs(58rem, max-width);
      font-size: 0.85vw;
    }

    @include breakpoint-down(mobile) {
      line-height: 1.65;
      padding: 0 10px;

    }
  }
}

