/* ------------------------------------------------------------ *\
	Navbar
\* ------------------------------------------------------------ */

.navbar {
	font-size: 16px;
	position: relative;

	@include breakpoint-up(xxl-desktop) {
		@include font-size(1rem);
	}

	@include breakpoint-down(tablet) {
		&:before {
			content: '';
			width: 100vw;
			height: 100%;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: 2;
			background-color: #fff;
		}
	}

	@include breakpoint-down(mobile) {
		padding: 0 8px 0 0;
	}

	.navbar-brand {
		margin-left: 5px;
		position: relative;
		z-index: 2;

		@include breakpoint-down(mobile) {
			margin-left: 9px;
		}
	}

	.navbar-brand svg {
		max-width: 165px;
		height: 72px;
		transition: max-width .5s, height .5s;

        @media (min-width: 1024px) {
            height: 62px;
        }

        @media (min-width: 1200px) and (max-width: 1399px) {
            height: 68px;
        }

		@include breakpoint-up(xxl-desktop) {
			@include rfs(14.25rem, max-width);
			@include rfs(6.06rem, height);
		}

		@include breakpoint-down(mobile) {
			max-width: 97px;
			height: 40px;
		}
	}

	.navbar-collapse {
		justify-content: flex-end;

		@include breakpoint-down(tablet) {
			position: fixed;
			top: 99px;
			bottom: 0;
			left: 0;
			right: 0;
			height: 100vh !important;
			background: $c-dark;
			z-index: 1;
			display: block;
			transform: translateY(-110%);
			transition: height .2s linear, transform .2s linear !important;
		}

		@include breakpoint-down(mobile) {
			top: 51px;
		}
	}

	.navbar-collapse.collapsing {
		transform: translateY(-55%);

	}
	.navbar-collapse.show {
		transform: translateY(0);
	}

	.navbar-nav {
		@include breakpoint-down(tablet) {
			height: 100%;
			padding: 156px 0 80px;
			align-items: center;
			overflow-y: auto;
		}
	}


	.nav-item + .nav-item {
		margin-left: 31px;

		@include breakpoint-down(small-desktop) {
			margin-left: 15px;
		}

		@include breakpoint-down(tablet) {
			margin-left: 0;
			margin-top: 40px;
		}

		@include breakpoint-down(mobile) {
			margin-top: 27px;
		}
	}

	.nav-link {
		color: $c-darker;
		letter-spacing: 0.3em;
		transition: color .3s;

        @media (min-width: 1024px) {
            font-size: 12px;
        }

        @media (min-width: 1200px) and (max-width: 1399px) {
            font-size: 14px;
        }

        @media (min-width: 1400px) {
            font-size: 15px;
        }

        @media (min-width: 16400px) {
            font-size: 16px;
        }

		&:hover {
			color: $c-brown;
		}

		@include breakpoint-down(tablet) {
			color: $c-white;
		}
	}

	.nav-item.is-active {
		position: relative;

		&:after {
			content: '';
			width: 100%;
			height: 4px;
			background-color: $c-brown;
			position: absolute;
			bottom: -32px;
			left: 0;
			transition: bottom $t-duration ease;

			@include breakpoint-down(tablet) {
				bottom: 0;
			}
		}
	}

	.nav-item.is-active .nav-link {
		color: $c-brown;
	}

	.navbar-toggler {
		position: relative;
		z-index: 5;
		width: 35px;
		height: 20px;
		padding: 0;
		border: 0;
		display: block;
		display: none;

		span {
			position: absolute;
			display: block;
			height: 4px;
			width: 100%;
			background: $c-brown-light;
			transition: top .2s .25s, opacity .2s .25s, transform .2s 0s;

			&:nth-child(1) {
				top: 0;
			}

			&:nth-child(2) {
				top: 8px;
			}

			&:nth-child(3) {
				top: 16px;
			}
		}

		&:focus {
			outline: none;
			box-shadow: none;
		}

		@include breakpoint-down(tablet) {
			display: block;
		}
	}

	.navbar-toggler[aria-expanded="true"] {
		span {
			transition: background .2s, top .2s, opacity .2s, transform .2s .25s;

			&:nth-child(3),
			&:nth-child(1) {
				top: 6px;
			}

			&:nth-child(2) {
				opacity: 0;
			}

			&:nth-child(1) {
				transform: rotate(45deg);
			}

			&:nth-child(3) {
				transform: rotate(-45deg);
			}
		}
	}
}
