/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header {
	box-shadow: 0px 8px 20px 0 rgba($c-black, .2);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	background-color: #fff;

	.container {
		max-width: 1445px;

		@include breakpoint-up(xxl-desktop) {
			@include rfs(133rem ,max-width)
		}
	}

	.header__bar {
		background: $c-dark;
		font-size: 14px;
		overflow: hidden;
		color: $c-brown-light;
		transition: max-height .5s linear;
		max-height: 4em;

		@include breakpoint-up(xxl-desktop) {
			@include font-size(0.875rem);
		}
	}

	.header__bar .container {
		max-width: 1496px;
		padding: 6px 0 8px;

		@include breakpoint-up(xxl-desktop) {
			@include rfs(137.7rem ,max-width)
		}
	}

	.header__bar-inner {
		padding-right: 30px;
	}

	.header__bar ul {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		list-style: none;

		@include breakpoint-down(small-desktop) {
			padding-left: 16px;
		}
	}

	.header__bar ul li + li {
		margin-left: 34px;
	}

	.header__bar ul a {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		text-decoration: underline;

		&:hover {
			color: $c-brown-light;
			text-decoration: none;
		}
	}

	.list__inner {
		display: flex;
		align-items: center;
	}
}

.header.is-scroll {
	.header__bar {
		max-height: 0;
		padding: 0;
	}

	.navbar .navbar-brand svg {
		@include breakpoint-up(mobile) {
			max-width: 140px;
			height: 57px;
		}
	}

	.nav-item.is-active {
		@include breakpoint-up(tablet) {
			&:after {
				bottom: -25px;
			}
		}
	}

		.navbar-collapse {
			@include breakpoint-between(mobile, tablet) {
				top: 84px;
			}
		}
}
