/* ------------------------------------------------------------ *\
	Section Products
\* ------------------------------------------------------------ */

.section-products {
	position: relative;
	padding: 59px 0 60px;
	background: $c-white-dark;

	@include breakpoint-down(tablet) {
		padding: 59px 0 20px;
	}

	@include breakpoint-down(mobile) {
		padding: 39px 0 20px;
	}

	.section__logo {
		@include sectionLogo();
		position: absolute;
		top: -65px;
		right: 165px;
		font-size: 195px;
		z-index: 1;

		@include breakpoint-up(xxl-desktop) {
			@include rfs(17.43rem ,font-size)
		}

		@include breakpoint-down(small-desktop) {
			font-size: 173px;
			right: 0;
		}
	}

	.section__inner {
		position: relative;
		z-index: 2;

		@include breakpoint-down(tablet) {
			padding: 0 8px;
		}
	}
}

.section-products--alt {
	.section__logo {
		right: auto;
		left: 200px;
		top: 98px;
		max-width: 400px;
		line-height: .8;
	}

	.section__content {
		height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 97px 22px 0 60px;

		@include breakpoint-down(small-desktop) {
			padding: 0px 22px 0 30px;
		}

		@include breakpoint-down(tablet) {
			padding: 0 0 60px;
			text-align: center;
		}
	}

	.section__title {
		margin-bottom: 14px;
	}

	.section__entry {
		line-height: 1.9;
		max-width: 600px;
		margin: 0 auto;

		@include breakpoint-down(mobile) {
			line-height: 1.66;
		}
	}
}
