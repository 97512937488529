/* ------------------------------------------------------------ *\
    Fonts
\* ------------------------------------------------------------ */

@font-face {
    font-family: 'Museo 300';
    src: url('../../fonts/Museo300-Regular.woff2') format('woff2'),
        url('../../fonts/Museo300-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

