/* ------------------------------------------------------------ *\
	Hero
\* ------------------------------------------------------------ */

.hero {
	padding: 106px 0 0;
	@include centerBackground;

	@include breakpoint-down(tablet) {
		position: relative;
		z-index: 2;
		padding: 0 0 40px;
		background-position: 100% 100%;
		background-size: 100% 60%;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: #5f5d5b;
			mix-blend-mode: multiply;
		}
	}

	@include breakpoint-down(mobile) {
		padding-bottom: 46px;

	}

	.container {
		max-width: 1433px;

		@include breakpoint-up(xxl-desktop) {
			@include rfs(131.9rem ,max-width)
		}
	}

	.hero__inner {
		position: relative;
		z-index: 2;
	}

	.hero__content {
		padding-top: 78px;
		padding-right: 40px;

		@include breakpoint-down(tablet) {
			padding-top: 90px;
			padding-right: 0;
			text-align: center;
		}

		@include breakpoint-down(mobile) {
			padding-top: 60px;
		}
	}

	.hero__title {
		color: $c-white;
		line-height: 1.13;
		font-size: 50px;

		@include breakpoint-up(xxl-desktop) {
			@include font-size(4.03rem);
		}

		@include breakpoint-down(mobile) {
			padding: 0 25px;
			font-size: 25px;
			line-height: 1.24;
		}
	}

	.hero__actions {
		padding-top: 61px;

		@include breakpoint-down(tablet) {
			display: flex;
			justify-content: center;
		}

		@include breakpoint-down(mobile) {
			padding-top: 25px;
		}
	}
}
