/* ------------------------------------------------------------ *\
	Block
\* ------------------------------------------------------------ */

.block {
	.block__image {
		position: relative;
		padding-top: calc(78% + 3px);
		margin-right: -10px;
		@include centerBackground;

		@include breakpoint-down(tablet) {
			margin-right: 0;
		}

		@include breakpoint-down(mobile) {
			padding-top: calc(68% + 3px);
		}
	}

	.block__decoration {
		position: absolute;
		bottom: -90px;
		right: 29px;

		@include breakpoint-down(mobile) {
			bottom: -48px;
			right: -2px;
			max-width: 180px;
		}
	}

	.block__content {
		position: relative;
		z-index: 2;
		width: 93%;
		padding-top: 32px;
		margin-left: -12px;
		font-size: 16px;
		color: $c-light;
		line-height: 1.9;

		@include breakpoint-down(tablet) {
			width: 100%;
			margin-left: 0;
			padding-top: 100px;
		}

		@include breakpoint-down(mobile) {
			padding-top: 68px;
			line-height: 1.66;
		}
	}

	.block__title {
		margin-bottom: 27px;
		color: $c-darker;
		line-height: 1.13;

		@include breakpoint-up(xxl-desktop) {
			@include font-size(4.03rem);
		}

		@include breakpoint-down(tablet) {
			text-align: center;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 19px;
			line-height: 1.28;
		}
	}

	.block__actions {
		padding-top: 19px;

		@include breakpoint-down(tablet) {
			display: flex;
			justify-content: center;
		}

		@include breakpoint-down(mobile) {
			padding-top: 13px;
		}
	}
}
