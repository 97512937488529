/* ------------------------------------------------------------ *\
	SEction Gallery
\* ------------------------------------------------------------ */

.section-gallery {
	position: relative;
	padding: 121px 0 256px;
	background: $c-white-dark;

	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 2000px;
		height: 443px;
		background: $c-brown-light;
		transform: translateX(-88.1%)
	}

	@include breakpoint-up(xxl-desktop) {
		&:before {
			width: calc(63% - 1vw);
		}
	}

	@include breakpoint-down(large-desktop) {
		&:before {
			width: 69%;
			height: 343px;
		}
	}

	@include breakpoint-down(tablet) {
		padding: 40px 0 180px;

		&:before {
			width: 100%;
			height: 380px;
			background: $c-brown-light;
		}
	}

	@include breakpoint-down(mobile) {
		padding: 35px 0 115px;

		&:before {
			height: 272px;
		}
	}

	.section__logo  {
		@include sectionLogo();
		position: absolute;
		top: 0;
		left: 50%;

		@include breakpoint-down(small-desktop) {
			font-size: 150px;
			top: 40px;
		}


		& > * {
			transform: translateX(-50%)
		}
	}

	.section__head {
		text-align: center;

		@include breakpoint-down(mobile) {
			padding: 0 10px;
		}
	}

	.section__title {
		color: $c-darker;

		@include breakpoint-up(xxl-desktop) {
			@include font-size(4.03rem);
		}

		@include breakpoint-down(mobile) {
			line-height: 1.24;
		}
	}

	.section__entry {
		max-width: 687px;
		margin: 23px auto;
		line-height: 1.9;

		@include breakpoint-down(mobile) {
			line-height: 1.65;
			margin: 14px auto 0;
		}
	}

	.section__actions {
		padding-top: 25px;
		display: flex;
		justify-content: center;

		@include breakpoint-down(mobile) {
			padding-top: 29px;
		}
	}

	.section__body {
		padding-top: 60px;

		@include breakpoint-down(mobile) {
			padding-top: 53px;
		}
	}
}

/* Section gallery alt */
.section-gallery--alt {
	padding: 91px 0 199px;

	@include breakpoint-down(tablet) {
		padding: 50px 0 100px;
	}

	@include breakpoint-down(mobile) {
		padding: 41px 0 70px;
	}

	&:before {
		content: none;
	}

	.section__entry p {
		margin-bottom: 1.9em;
	}

	.section__body {
		@include breakpoint-down(mobile) {
			padding-top: 13px;
		}
	}
}
