/* ------------------------------------------------------------ *\
	Section Blocks
\* ------------------------------------------------------------ */

.section-blocks {
	position: relative;
	z-index: 1;
	padding: 151px 0 150px;
	background: $c-white-dark;

	&:before {
		content: '';
		position: absolute;
		top: -102px;
		right: calc(50% + 186px);
		width: 100%;
		height: calc(133% + 104px);
		background: $c-brown-light;
	}

	@include breakpoint-up(xxl-desktop) {
		&:before {
			right: calc(50% + 10vw);
			@include rfs(-8rem, top);
		}
	}

	@media screen and (max-width: 1450px) {
		&:before {
			width: calc(33.3333% + 53px);
			left: 0;
			right: auto;
		}
	}

	@include breakpoint-down(tablet) {
		padding: 80px 0 40px;

		&:before {
			display: none;
		}
	}

	@include breakpoint-down(mobile) {
		padding: 53px 0 30px;
	}


	.section__logo {
		@include sectionLogo();
		position: absolute;
		top: 67px;
		left: calc(50% - 65px);

		@include breakpoint-up(xxl-desktop) {
			@include rfs(17.43rem ,font-size)
		}

		@include breakpoint-down(small-desktop) {
			font-size: 106px;
		}
	}

	.section__inner {
		@include breakpoint-down(tablet) {
			padding: 0 8px;
		}
	}
}
