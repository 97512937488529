/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.btn {
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	appearance: none;
}

/*  Btn block  */

.btn--block {
	display: block;
	padding-left: 0;
	padding-right: 0;
}

.btn-rounded {
	width: fit-content;
	height: 53px;
	padding: 6px 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 3px solid #b78d63;
	border-radius: 25px;
	color: $c-white;
	line-height: 1;
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: 0.3em;
	transition: background .3s;

	.arrow-right {
		position: relative;
		right: 0;
		margin-left: 16px;
		transition: right .2s linear;
		margin-bottom: 1px;
	}

	&:hover {
		color: $c-white;
		background: #b78d63;

		.arrow-right {
			right: -5px;
		}
	}

	@include breakpoint-up(xxl-desktop) {
		@include rfs(25.9rem ,max-width);
		@include rfs(4.31rem ,height);
		@include rfs(1.3rem ,font-size);
		@include rfs(2.3rem ,border-radius);
		justify-content: center;
	}

	@include breakpoint-down(mobile) {
		padding: 0 18px 0 22px;
	}
}

.btn-rounded--dark {
	color: $c-darker;
}

.btn-rounded--plus {
	i {
		padding-bottom: 3px;
		transition: transform .2s ease-in-out;
	}

	&:hover i {
		padding-bottom: 0;
		transform: rotate(90deg) translateX(-2px);
	}
}
