/* ------------------------------------------------------------ *\
	Product
\* ------------------------------------------------------------ */

.product {
	position: relative;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 657px;
	text-align: center;
	color: $c-white;
	text-transform: uppercase;
	text-decoration: none;
	overflow: hidden;
	padding: 15px;

	&:hover {
		color: $c-white;

		.product__image {
			transform: scale(1);

			&:before {
				background: rgb(175, 175, 175);
			}
		}

		.product__inner {
			transform: translate3d(0, -40px, 0);
		}

		.product__actions {
			opacity: 1;
			bottom: 80px;
			transform: scale(1);
			pointer-events: all;
		}
	}

	@include breakpoint-up(xxl-desktop) {
		@include rfs(60.2rem ,min-height);
	}

	@include breakpoint-down(small-desktop) {
		min-height: 557px;
	}

	@include breakpoint-down(tablet) {
		margin-bottom: 40px;
	}

	@include breakpoint-down(mobile) {
		min-height: 280px;
		margin-bottom: 12px;

		&:hover {
			.product__inner {
				transform: translate3d(0, -25px, 0);
			}

			.product__actions {
				bottom: 20px;
			}
		}
	}

	.product__image {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: -1;
		@include centerBackground;
		transition: transform .3s;
		transform: scale(1.15);

		&:before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: $c-gray;
			mix-blend-mode: multiply;
			transition: background .3s;
		}
	}

	.product__inner {
		padding-bottom: 13px;
		transition: transform .3s;

		@include breakpoint-down(mobile) {
			padding-top: 4px;
			padding-bottom: 0;
		}
	}

	.product__icon {
		margin-bottom: 30px;

		@include breakpoint-down(mobile) {
			max-width: 91px;
			margin: 0 auto 20px;
		}
	}

	.product__title {
		line-height: 1.33;

		@include breakpoint-up(xxl-desktop) {
			@include rfs(1.75rem ,font-size);
		}
	}

	.product__actions {
		position: absolute;
		bottom: 40px;
		width: 100%;
		display: flex;
		justify-content: center;
		transition: bottom .3s;
		opacity: 0;
		pointer-events: none;
		transition: bottom .3s, opacity .35s, transform .35s;
		transform: scale(1.15);

		@include breakpoint-down(mobile) {
			bottom: 0;
		}
	}

	br {
		@include breakpoint-down(mobile) {
			display: none;
		}
	}
}

.product--alt {
	max-width: 432px;
	margin-left: auto;

	@include breakpoint-down(tablet) {
		max-width: 100%;
		margin-left: 0;
	}
}
