/* ------------------------------------------------------------ *\
	Fields
\* ------------------------------------------------------------ */

.field {
	border: none;
	outline: none;
	background-color: transparent;
	width: 100%;
	border-bottom: 1px solid rgba($c-light, .6);
	border-radius: 0;
	padding: 7px 8px;

	&.invalid {
		border-color: $c-error
	}

	&.valid {
		border-color: $c-success;
	}

	&::placeholder {
		color: $c-light;
		opacity: .6;
	}

}

/*  Field textarea  */
.field--textarea {
	resize: none;
	max-height: 100%;
}

.textarea-wrapper {
	height: 95px;
	display: flex;
	align-items: flex-end;
}
