/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */

.wrapper {
	position: relative;
	min-height: 100vh;
	overflow: hidden;
	padding-top: 130px;

	@include breakpoint-down(tablet) {
		padding-top: 100px;
	}

	@include breakpoint-down(mobile) {
		padding-top: 50px;
	}
}
