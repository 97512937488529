/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body {
	min-width: 320px;
	background: #fff;
	font-family: $ff-serif;
	font-weight: $font-weight-base;
	font-size: $font-size-base;
	line-height: $line-height-base;
	color: #000;
}

body.is-locked {
	overflow: hidden;
}


.container {
	max-width: 1445px;
	padding: 0 20px;

	@include breakpoint-up(xxl-desktop) {
		@include rfs(131.9rem ,max-width)
	}
}

.container-xxl {
	@include breakpoint-up(xxl-desktop) {
		@include rfs(131.9rem ,max-width)
	}
}


a {
	color: inherit;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}

	&[href^="tel"] {
		text-decoration: none;
	}
}


.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
	margin-bottom: calc(#{$line-height-base}em / 2);
}

.h1,
h1 {
	font-size: 55px;

	@include breakpoint-down(mobile) {
		font-size: 46px;
	}

	@include breakpoint-down(mobile-small) {
		font-size: 30px;
	}
}


.h2,
h2 {
	font-size: 50px;

	@include breakpoint-up(xxl-desktop) {
		font-size: 2.6vw;
	}

	@include breakpoint-down(mobile) {
		font-size: 40px;
	}

	@include breakpoint-down(mobile-small) {
		font-size: 25px;
	}
}

.h3,
h3 {
	font-size: 30px;

	@include breakpoint-down(mobile) {
		font-size: 20px;
	}
}

.h4,
h4 {
	font-size: 25px;

	@include breakpoint-down(mobile) {
		font-size: 16px;
	}
}

.h5,
h5 {
	font-size: 16px;
}

.h6,
h6 {
	font-size: 14px;
}


p,
ul,
ol,
dl,
hr,
table,
blockquote {
	margin-bottom: #{$line-height-base}em;
}

h1[class],
h2[class],
h3[class],
h4[class],
h5[class],
h6[class],
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child {
	margin-bottom: 0;
}
