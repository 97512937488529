/* ------------------------------------------------------------ *\
  section-50-50
\* ------------------------------------------------------------ */

.section-50-50 {
  padding: 90px 0 100px;
  background-color: $c-white-dark;
  position: relative;
  overflow: hidden;
  z-index: 1;

	@include breakpoint-down(tablet) {
    padding: 60px 0;
  }

  @include breakpoint-down(mobile) {
    padding: 25px 0 30px;
  }

  .section__image {
    @include centerBackground;
    padding-top: 77%;
    position: relative;
    margin-top: 25px;
    margin-left: -10px;
    margin-bottom: 155px;

    @include breakpoint-down(tablet) {
      margin-left: 0;
      margin-bottom: 25vw;
    }

    @include breakpoint-down(mobile) {
      margin-top: 0;
      margin-left: 10px;
      margin-right: 10px;
      padding-top: 73%;
      margin-bottom: 34vw;
    }

    &:before {
      content: '';
      background-color: $c-brown-light;
      width: 500%;
      height: calc(100% + 120px);
      position: absolute;
      bottom: 0;
      left: 34%;
      z-index: -1;
    }
  }

  .section__decoration {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-71%, 61%);
    box-shadow: 0px 8px 20px 0 rgba($c-black, .2);
  }

  .section__content {
    max-width: 575px;
    padding-right: 65px;
    margin: 0 auto;

    @include breakpoint-up(xxl-desktop) {
      @include rfs(53rem, max-width);
      font-size: 0.85vw;
    }

    @include breakpoint-down(tablet) {
      text-align: center;
      padding-right: 0;
      padding: 0 10px;
    }
  }

  .section__title {
    margin-bottom: 36px;

    @include breakpoint-up(xxl-desktop) {
      font-size: 2.5vw;
    }

    @include breakpoint-down(mobile) {
      margin-bottom: 20px;
    }
  }

  .section__entry {
    line-height: 1.9;

    @include breakpoint-down(mobile) {
      line-height: 1.6;
    }
  }

  .section__entry p {
    margin-bottom: 1.9em;
  }
}
