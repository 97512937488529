/* ------------------------------------------------------------ *\
	slider-hero-thumbs
\* ------------------------------------------------------------ */

.slider-hero-thumbs-wrapper {
	position: relative;
	z-index: 2;
	padding-top: 155px;

	// @include breakpoint-up(xxl-desktop) {
	// 	@include padding-top(13.74rem);
	// }

	@include breakpoint-down(small-desktop) {
		padding-top: 114px;
	}

	@include breakpoint-down(tablet) {
		display: none;
	}

	.tns-ovh {
		padding-bottom: 40px;
		overflow: visible;
	}

	.tns-inner {
		margin: 0 23px 0 0 !important;

		@include breakpoint-down(small-desktop) {
			margin: 0 !important;
		}
	}
}

.slider-hero-thumbs {
	.slider__slide {
		position: relative;
		padding-right: 19px !important;

		@include breakpoint-down(small-desktop) {
			padding-right: 10px !important;
		}
	}

	.tns-nav-active .slider__image {
		box-shadow: 0 8px 14px 1px rgba(0 ,0 ,0,.2);
	}

	.slider__image {
		position: relative;
		padding-top: 79%;
		@include centerBackground;
		transition: box-shadow .3s;

		&:before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 40px;
			background: linear-gradient(to top, rgba(0, 0, 0, .7), rgba(0, 0, 0, .1));
		}

		&:hover {
			box-shadow: 0 8px 14px 1px rgba(0 ,0 ,0,.2);
		}
	}

	.slider__progress {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0;
		right: 0;
		height: 5px;
		background: $c-white;
	}

	.tns-nav-active .slider__progress {
		animation: progress 4s linear;
	}

	@keyframes progress {
		0% {
			width: 0;
		}

		100% {
			width: 100%;
		}
	}
}
