/* ------------------------------------------------------------ *\
  section-form-contact
\* ------------------------------------------------------------ */

.section-form-contact {
  background-color: $c-white-dark;

  .section__content {
    padding: 90px 0 125px;
    max-width: 615px;

		@include breakpoint-up(xxl-desktop) {
      max-width: 32vw;
      font-size: 0.8vw;
		}

    @include breakpoint-down(tablet) {
      margin: 0 auto;
      padding: 50px 0 80px;
    }

    @include breakpoint-down(mobile) {
      margin: 0 auto;
      padding: 40px 0 75px;
    }
  }

  .section__image {
    @include centerBackground();
    height: 100%;
		margin-right: calc((-100vw + 1407px) / 2);

		@include breakpoint-up(xxl-desktop) {
      margin-right: calc((-100vw + calc(14.315rem + 61.71vw)) / 2);
		}

		@media(max-width: 1407px) {
      margin-right: -15px;
		}
  }
}
body > div.wrapper > div.main > section.section-form-contact > div > div > div.col-xxl-8.col-lg-7.aos-init.aos-animate > div > div > form > div.form__actions > div > label > a:hover{
  color:  #b78d63;
}
