/* ------------------------------------------------------------ *\
  section-history
\* ------------------------------------------------------------ */

.section-history {
  padding: 61px 0 130px;
  background-color: $c-white-dark;

  @include breakpoint-down(tablet) {
    padding: 30px 0 130px;
  }

  @include breakpoint-down(mobile) {
    padding: 8px 0 106px;
  }

  .container {
    @include breakpoint-down(tablet) {
      max-width: 100%;
      padding: 0;
    }
  }
}
