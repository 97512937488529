/* ------------------------------------------------------------ *\
	Checkbox
\* ------------------------------------------------------------ */

.checkbox {
	input {
		// display: none;
	}

	label {
		position: relative;
		display: inline-block;
		padding-left: 34px;

		&:before {
			content: '';
			display: inline-block;
			vertical-align: middle;
			position: absolute;
			top: -0.11em;
			left: 0;
			width: 26px;
			height: 26px;
			border: 3px solid #b78d63;
			transform: translateY(-3px);
		}

		&:after {
			content: '';
			display: none;
			vertical-align: middle;
			width: 15px;
			height: 8px;
			border-bottom: 3px solid #b78d63;
			border-left: 3px solid #b78d63;
			transform: rotate(-45deg);
			position: absolute;
			left: 6px;
			top: .1em;
		}
	}

	input:checked + label {
		&:after {
			display: inline-block;
		}
	}
}
