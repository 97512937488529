body{
    background-color: $secondary;
}

.storia{
    .tns-horizontal.tns-subpixel#tns3{
        width: 100%;
    }
}


.text-block{
    position: relative;
    overflow: hidden;

    .section__entry{
        max-width: 100%;
    }

    span{
        font-size: 195px;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #e5dbcf;
        text-transform: uppercase;
        white-space: nowrap;
        position: absolute;
        opacity: .3;
        left: -5%;
        top: -16%;

        @media (max-width: 1199px) {
            font-size: 175px;
        }

        @media (max-width: 767px) {
            font-size: 145px;
        }

    }

}

.section-tabs{

    &--spaced-top{
        margin-top:70px;
    }

    &--spaced-bottom{
        margin-bottom:140px;
    }

    &--partial-background{
        position: relative;

        &:after{
            content: "";
            position: absolute;
            left: 0px;
            bottom: -145px;
            width: calc(942px + (50vw - 704.5px));
            height: 350px;
            background: $primary;
            z-index: 0;

            @media (max-width: 1399px) {
                height: 280px;
                width: 66%;
            }

            @media (max-width: 1199px) {
                height: 310px;
                width: 70%;
            }

            @media (max-width: 991px) {
                height: 310px;
                width: 100%;
            }

            @media (max-width: 575px) {
                height: 270px;
                width: 100%;
            }

        }
    }

    .section__body{
        position: relative;
        z-index: 1;
    }

}

.slider-galley-wrapper{
    z-index: 1;
}

.overflower{
    overflow-x:auto;
    max-width: calc(100% - 50px);

    &::-webkit-scrollbar{
        display: none;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

}

.arrow{
    position: relative;
    width: 25px;
    cursor: pointer;


    img{
        pointer-events: none;
    }

    &--left{
        &:after{
            content:"";
            position: absolute;
            left:100%;
            top:0;
            height:100%;
            width: 45px;
            background: linear-gradient(270deg, rgba($secondary, 0) 25%, rgba($secondary,1) 100%);
            z-index: 1;
        }
    }

    &--right{
        &:before{
            content:"";
            position: absolute;
            right:100%;
            top:0;
            height:100%;
            width: 45px;
            background: linear-gradient(90deg, rgba($secondary, 0) 25%, rgba($secondary,1) 100%);
            z-index: 1;
        }
    }

}
#servizi-offerti{
    padding-top: 70px !important;
    margin-top: 0;

}
.slider-count{
    text-align: center;
    font-size: 50px;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(70, 43, 47);
    position: absolute;
    width: 100%;
    white-space: nowrap;
    padding-top:50px;

    @media (max-width: 1199px) {
        font-size: 38px;
    }

    @media (max-width: 767px) {
        font-size: 30px;
    }

}
.text-fill-filled{
    -webkit-text-fill-color: initial;
}
.thank-you-section{
    padding: 200px  0;
}