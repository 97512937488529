/* ------------------------------------------------------------ *\
	SEction Contacts
\* ------------------------------------------------------------ */

.section-contacts {
	padding: 0 0 0;

	@include breakpoint-down(small-desktop) {
		text-align: center;
	}

	.section__content {
		position: relative;
		z-index: 2;
		margin-right: -14px;
		padding: 129px 10px 145px 0;
		background: $c-darker;
		font-size: 25px;
		color: $c-white;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: -100%;
			width: 101%;
			z-index: -1;
			background: $c-darker;
		}

		@include breakpoint-down(small-desktop) {
			padding: 90px 10px;
			margin-right: 0;
			font-size: 20px;

			&:before {
				width: auto;
				right: -50px;
			}
		}

		@include breakpoint-down(mobile) {
			padding: 58px 7px 68px;
			font-size: 16px;
		}
	}

	.section__decoration {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: -1;
		overflow: hidden;
		opacity: .3;

		&:after {
			content: '';
			position: absolute;
			top: -68px;
			bottom: 0;
			right: -93px;
			z-index: -1;
			width: 83%;
			@include centerBackground;
			background-image: url(/img/decoration@2x.png);
		}
	}

	.section__content a {
		font-size: 25px;
		color: $c-brown-light;
		text-decoration: underline;
		transition: color .3s;

		&:hover {
			color: $c-white;
		}

		@include breakpoint-down(small-desktop) {
			font-size: 20px;
		}

		@include breakpoint-down(mobile) {
			font-size: 16px;
		}
	}

	.section__content a + p {
		margin-top: 23px;

		@include breakpoint-down(mobile) {
			margin-top: 19px;
		}
	}

	.section__content p + h2 {
		padding-top: 35px;
	}

	.section__actions {
		padding-top: 2px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		@include breakpoint-down(small-desktop) {
			align-items: center;
		}
	}

	.section__actions a {
		display: inline-block;
	}

	.section__actions a + a {
		margin-top: 20px;

		@include breakpoint-down(mobile) {
			margin-top: 16px;
		}
	}

	.section__title {
		margin-bottom: 27px;
		line-height: 1.15;

		@include breakpoint-up(xxl-desktop) {
			@include font-size(4.03rem);
		}

		@include breakpoint-down(small-desktop) {
			font-size: 40px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 28px;
			line-height: 1.28;
		}
	}

	br {
		@include breakpoint-down(mobile) {
			display: none;
		}
	}

	.section__aside {
		height: 100%;
		margin-right: calc((-100vw + 1407px) / 2);

		@include breakpoint-up(xxl-desktop) {
			margin-right: calc((-100vw + calc(14.315rem + 61.71vw)) / 2);
		}

		@media(max-width: 1407px) {
			margin-right: -20px;
		}

		@include breakpoint-down(small-desktop) {
			margin-left: -20px;
		}

	}
}
