/* ------------------------------------------------------------ *\
  form
\* ------------------------------------------------------------ */

.form {
  @include breakpoint-down(tablet) {
    text-align: center;
  }

  @include breakpoint-down(mobile) {
    padding: 0 10px;
  }

  .form__head {
    margin-bottom: 44px;

    @include breakpoint-down(mobile-small) {
      margin-bottom: 55px;
    }
  }

  .form__title {
    margin-bottom: 24px;

    @include breakpoint-down(mobile-small) {
      margin-bottom: 15px;
    }
  }

  .form__subtitle {
    max-width: 450px;
    line-height: 1.9;

    @include breakpoint-down(tablet) {
      margin: 0 auto;
    }

    @include breakpoint-down(mobile-small) {
      line-height: 1.6;
    }
  }

  .form__body {
    margin-bottom: 50px;

    @include breakpoint-down(mobile) {
      margin-bottom: 45px;
    }
  }

  .form__row {
    margin-bottom: 22px;
  }

  .form__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint-down(mobile) {
      flex-direction: column;
      justify-content: center;
    }
  }

  .form__btn {
    background-color: transparent;

    @include breakpoint-down(mobile) {
      margin-top: 40px;
      width: 100%;
      justify-content: center;
      max-width: 338px;
    }

    .arrow-right {
      @include breakpoint-down(mobile) {
        margin-left: 7px;
      }
    }

    &:hover {
      background-color: #b78d63;
    }
  }
}
