/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
	padding: 39px 0 42px;
	background: $c-dark;
	color: $c-white;

	@include breakpoint-down(mobile) {
		padding: 22px 0 42px;
	}

	.container {
		max-width: 1434px;

		@include breakpoint-up(xxl-desktop) {
			@include rfs(131.9rem ,max-width)
		}
	}

	.footer__inner {
		@include breakpoint-down(mobile) {
			padding: 0 8px;
		}
	}

	ul {
		padding-left: 40px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		list-style: none;

		@include breakpoint-down(tablet) {
			padding-left: 0;
		}
	}

	li {
		position: relative;
		padding-right: 13px;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			width: 1px;
			background: $c-white;
		}

		&:last-child {
			&:before {
				display: none;
			}
		}

		@include breakpoint-down(mobile-small) {
			padding-right: 5px;
		}
	}

	li + li {
		padding-left: 15px;

		@include breakpoint-down(mobile-small) {
			padding-left: 5px;
		}
	}

	a {
		&:hover {
			color: $c-white;
			text-decoration: none;
		}
	}

	.footer__info {
		padding-bottom: 30px;
		text-align: center;

		@include breakpoint-down(mobile) {
			padding-bottom: 37px;
			line-height: 1.6;
		}
	}
}
