/* ------------------------------------------------------------ *\
  slider-history
\* ------------------------------------------------------------ */

.slider-history-wrapper {
  max-width: 870px;
  margin: 66px auto;
  position: relative;

  @include breakpoint-up(xxl-desktop) {
    max-width: 45.2vw;
  }

  @include breakpoint-down(tablet) {
    margin-top: 0;
    max-width: 100%;
  }

  .slide__image {
    @include centerBackground();
    filter: grayscale(100%);
    width: 265px;
    height: 265px;
    border-radius: 100%;
    position: relative;

    @include breakpoint-up(xxl-desktop) {
      width: 13.8vw;
      height: 13.8vw;
    }

		@include breakpoint-down(tablet) {
      margin: 0 auto;
    }

		@include breakpoint-down(mobile) {
      width: 143px;
      height: 143px;
		}

    &:before {
      @include breakpoint-down(tablet) {
        content: '';
        width: 100vw;
        height: 1px;
        background-color: $c-brown-light;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        z-index: -1;
      }
    }
  }

  .slider__slide--first .slide__image:before {
    width: 50vw;
    left: 100%;
    transform: translate(0, -50%);
  }

  .slider__slide--last .slide__image:before {
    width: 50vw;
    left: auto;
    right: 100%;
    transform: translate(0, -50%);
  }

  .slide__content {
    padding-left: 56px;
    line-height: 1.9;
    position: relative;
    z-index: 1;

    @include breakpoint-up(xxl-desktop) {
      font-size: 0.85vw;
    }

		@include breakpoint-down(tablet) {
      padding-left: 0;
      text-align: center;
		}
  }

  .slide__entry {
    padding-top: 37px;

		@include breakpoint-down(tablet) {
      max-width: 540px;
      margin: 0 auto;
      padding: 20px 20px 0;
		}
  }

  .slide__logo {
    @include sectionLogo();
		position: absolute;
		top: -154px;
		left: -7px;
    z-index: -1;

		@include breakpoint-down(tablet) {
      font-size: 16px;
      -webkit-text-fill-color: $c-brown-light;
      -webkit-text-stroke-width: 0;
      position: static;
      text-align: center;
      width: 60px;
      max-width: 60px;
      height: 60px;
      line-height: 60px;
      margin: -37px auto 0;
      border-radius: 50%;
      border: 2px solid $c-brown-light;
      background-color: $c-white;
      box-shadow: 0px 7px 10px 0px rgba( $c-black , .2);
		}
  }

	.tns-controls {
		@include sliderControls();
    max-width: 95vw;
    width: 162.4%;

		@include breakpoint-up(xxl-desktop) {
			max-width: 84vw;
		}

		@include breakpoint-down(tablet) {
      top: 114%;
      max-width: 142px;
		}
	}

	.tns-controls button {
		@include sliderButton();
	}

	.tns-controls button + button {
		@include sliderButtonRight();
	}

}
