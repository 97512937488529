/* ------------------------------------------------------------ *\
  slider-history-thumbs
\* ------------------------------------------------------------ */

.slider-history-thumbs-wrapper {
  position: relative;

  @include breakpoint-down(tablet) {
    display: none;
  }

  .slider-history-thumbs {
    text-align: center;
    padding: 20px 0;
  }

  .slider__line {
    width: 100%;
    height: 1px;
    background-color: $c-brown-light;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    pointer-events: none;

    &:before,
    &:after {
      content: '';
      width: 9%;
      height: 10px;
      position: absolute;
      pointer-events: none;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      box-shadow: 15px 0px 11px -1px rgba($c-white-dark,0.8) inset;
    }

    &:after {
      left: auto;
      right: 0;
      box-shadow: -15px 0px 11px -1px rgba($c-white-dark,0.8) inset;
    }
  }


  .slider__slide {
    width: 70px;
    max-width: 70px;
    height: 70px;
    margin: 0 45px;
    padding-right: 0 !important;
    border-radius: 50%;
    position: relative;
    border: 2px solid transparent;
    z-index: 1;
    background-color: $c-white-dark;
    transition: transform $t-duration ease, background-color $t-duration ease-in-out, box-shadow $t-duration ease-in-out, border-color $t-duration ease-in-out, color $t-duration ease-in-out ;


    @include breakpoint-up(xxl-desktop) {
      width: 3.64vw;
      height: 3.64vw;
      max-width: 3.64vw;
    }

    &:hover {
      transform: translateY(3px);
    }
  }

  .slider__control  {
    text-align: center;
    line-height: 70px;
    font-size: 20px;

    @include breakpoint-up(xxl-desktop) {
      font-size: 1vw;
      line-height: 3.64vw;
    }
  }

  .tns-nav-active {
    background-color: $c-white;
    border: 2px solid $c-brown-light;
    box-shadow: 0px 7px 10px 0px rgba( $c-black , .2);
    color: $c-brown-light;
  }


}
