/* ------------------------------------------------------------ *\
	Icons
\* ------------------------------------------------------------ */

[class*='ico-'] {
	display: inline-block;
    vertical-align: middle;
    font-size: 0;
}

.ico-mail {
	svg {
		width: 16px;
		height: 13px;
	}
}

.ico-phone {
	svg {
		width: 16px;
		height: 15px;
	}
}

.ico-clock {
	svg {
		width: 17px;
		height: 17px;
	}
}

.ico-facebook {
	svg {
		width: 8px;
		height: 16px;
	}
}

.ico-whatsapp {
	svg {
		width: 16px;
		height: 16px;
	}
}

.ico-instagram {
	svg {
		width: 14px;
		height: 14px;
	}
}

.ico-facebook-white {
	svg {
		width: 13px;
		height: 27px;

		@include breakpoint-up(xxl-desktop) {
			@include rfs(1rem, width);
			@include rfs(1.93rem, height);
		};
	}
}

.ico-whatsapp-white {
	svg {
		width: 27px;
		height: 27px;

		@include breakpoint-up(xxl-desktop) {
			@include rfs(1.93rem, width);
			@include rfs(1.93rem, height);
		};
	}
}

.ico-instagram-white {
	svg {
		width: 23px;
		height: 23px;

		@include breakpoint-up(xxl-desktop) {
			@include rfs(1.63rem, width);
			@include rfs(1.63rem, height);
		};
	}
}

.ico-cucine {
	margin-right: 5px;

	svg {
		width: 68px;
		height: 44px;

		@include breakpoint-down(mobile) {
			width: 54px;
		}
	}
}

.ico-giorno {
	margin-right: 5px;

	svg {
		width: 55px;
		height: 44px;

		@include breakpoint-down(mobile) {
			width: 40px;
		}
	}
}

.ico-notte {
	margin-right: 8px;

	svg {
		width: 55px;
		height: 44px;

		@include breakpoint-down(mobile) {
			width: 48px;
		}
	}
}
.ico-uffici {
	margin-right: 8px;

	svg {
		width: 55px;
		height: 44px;

		@include breakpoint-down(mobile) {
			width: 48px;
		}
	}
}
.ico-bagni {
	margin-right: 8px;

	svg {
		width: 55px;
		height: 44px;

		@include breakpoint-down(mobile) {
			width: 48px;
		}
	}
}

.ico-plus {
	margin-left: 15px;

	svg {
		width: 15px;
		height: 15px;
	}
}
