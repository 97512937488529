/* ------------------------------------------------------------ *\
	Slider Hero
\* ------------------------------------------------------------ */

.slider-hero-wrapper {
	position: relative;
	padding-left: 38px;
	margin-right: calc((-100vw + 1409px) / 2);
	box-shadow: 0 100px 70px -112px rgba(0,0,0,.4);

	@include breakpoint-up(xxl-desktop) {
		margin-right: calc((-100vw + calc(14.315rem + 61.71vw)) / 2);
	}

	@media(max-width: 1438px) {
		margin-right: -20px;
	}

	@include breakpoint-down(tablet) {
		padding-left: 0;
		margin-left: -20px;
	}

	.slider-hero-wrapper__decoration {
		position: absolute;
		top: -45px;
		left: -10px;
		width: 135px;
		height: 79%;
		background: $c-brown;

		@include breakpoint-down(tablet) {
			left: 0;
			top: 100%;
			width: 77%;
			height: 48px;
		}

		@include breakpoint-down(mobile) {
			height: 24px;
		}
	}
}

.slider-hero {
	.slider__slide {
		height: 724px;
		@include centerBackground;

		@include breakpoint-up(xxl-desktop) {
			@include rfs(66.33rem ,height)
		}

		@include breakpoint-down(small-desktop) {
			height: 729px;
		}

		@include breakpoint-down(tablet) {
			height: auto;
			padding-top: 12%;
		}

		@include breakpoint-down(mobile) {
			padding-top: calc(14% + 3px);
		}
	}
}
