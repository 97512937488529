//Variables
$font-size-base             : 1rem;
$font-weight-base           : 400;
$line-height-base           : 1.2;

$ff-sans-serif              : "Helvetica Neue", Helvetica, Arial, sans-serif;
$ff-serif                   : 'Museo 300' ,Georgia, "Times New Roman", Times, serif;

$t-duration                 : 0.4s;

$shell-max-width            : 1140px;
$shell-gutter               : 15px;

$c-black                    : #000;
$c-white                    : #fff;
$c-base                     : #055590;

$primary                    : #b18359;
$secondary                  : #ede9e3;

$responsive-ios             : 812px;

$breakpoints: (
	mobile-small: 374px,
	mobile-medium: 575px,
	mobile: 767px,
	admin-bar: 782px,
	tablet: 1023px,
	small-desktop: 1199px,
	large-desktop: 1399px,
	xxl-desktop: 1920px
);

$c-dark: #3d2427;
$c-darker: #462b2f;
$c-brown: #b18359;
$c-brown-light: #bd9166;
$c-brown-lighter: #e1d5c8;
$c-light: #6b5559;
$c-white-dark: #ede9e3;
$c-gray: #8d8d8d;
$c-error: #d81919;
$c-success: #067915;

$rfs-breakpoint: 3000px !default;
$rfs-mode: min-media-query !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1435px
);
