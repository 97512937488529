/* ------------------------------------------------------------ *\
  tabs-gallery
\* ------------------------------------------------------------ */

.tabs-gallery {
  .tabs__head {
    border-bottom: 1px solid rgba($c-darker, .2);
    border-top: 1px solid rgba($c-darker, .2);
    text-transform: uppercase;
    letter-spacing: .3em;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    @include breakpoint-up(xxl-desktop) {
      font-size: 0.85vw;
    }

    @include breakpoint-down(mobile) {
      margin-right: -20px;
    }
  }

  .tabs__nav ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: 0 auto;

    @include breakpoint-down(mobile) {
    }
  }

  .tabs__nav li {
    margin: 0 30px;
    padding: 17px 0;
    position: relative;
    white-space: nowrap;
      text-align: center;

      @media (min-width: 1200px) {
          padding: 30px 0;
      }

    @include breakpoint-down(mobile) {
      margin: 0 27px;
      padding: 16px 0;

        a{
            font-size: 14px;
        }

    }

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 3px;
      background-color: $c-brown-light;
      position: absolute;
      left: 0;
      bottom: -1px;
      transform-origin: right;
      transform: scaleX(0);
      transition: transform $t-duration ease-in-out;
    }

    &.is-current {
      color: $c-brown-light;
    }

    &.is-current:after {
      transform: scaleX(1);
    }
  }

  .tabs__nav a {
    text-decoration: none;
    display: flex;
    align-items: center;
    transition: color $t-duration ease-in-out;

    &:hover {
      color: inherit;
      color: $c-brown-light;
    }

    i{
      padding-right : 10px;
    }
  }

  .tabs__body {
    padding: 37px 0;

    @include breakpoint-down(mobile) {
      padding: 27px 8px;
    }
  }

  .row > * {
    @include breakpoint-down(mobile) {
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  .tab__image-wrapper {
    margin: 15px 0;
    overflow: hidden;

    @include breakpoint-down(mobile) {
      margin: 5px 0;
    }
  }

  .tab__image {
    @include centerBackground();
    padding-bottom: 69.4%;
    transition: transform $t-duration ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }

  .tab__actions a , .tab__download a {
    margin: 57px auto 0;

    @include breakpoint-down(mobile) {
      margin-top: 38px;
    }
  }
}
