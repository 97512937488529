/* ------------------------------------------------------------ *\
	Map
\* ------------------------------------------------------------ */

.map {
	width: 100%;
	height: 100%;

	@include breakpoint-down(small-desktop) {
		height: 500px;
	}

	@include breakpoint-down(mobile) {
		height: 373px;
	}
}
