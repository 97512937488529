/* ------------------------------------------------------------ *\
	Slider Gallery
\* ------------------------------------------------------------ */

.slider-galley-wrapper {
	position: relative;

	.tns-ovh {
		overflow: visible;
	}

	.tns-controls {
		@include sliderControls();
		max-width: calc(100% - 460px);
		left: 230px;
		transform: translateY(-50%);

		@include breakpoint-down(small-desktop) {
			max-width: 83vw;
			max-width: calc(100% - 180px);
			left: 90px;
		}

		@include breakpoint-down(tablet) {
			display: none;
		}
	}

	.tns-controls button {
		@include sliderButton();
	}

	.tns-controls button + button {
		@include sliderButtonRight();
	}

	.tns-nav {
		position: absolute;
		bottom: -70px;
		left: 50%;
		transform: translateX(-50%);

		@include breakpoint-down(mobile) {
			bottom: -38px;
			width: 100%;
			display: flex;
			justify-content: center;
		}
	}

	.tns-nav button {
		width: 28px;
		height: 4px;
		border: 0;
		background: $c-white;
		opacity: .5;
		transition: opacity .3s, height .3s;
		will-change: height;
	}

	.tns-nav button + button {
		margin-left: 37px;

		@include breakpoint-down(mobile) {
			margin-left: 23px;
		}
	}

	.tns-nav .tns-nav-active {
		height: 4px;
		opacity: 1;
	}
}

.slider-galley-wrapper--alt {
	.tns-nav button {
		background: $c-brown-light;
		opacity: .3;
		height: 4px;
	}

	.tns-nav .tns-nav-active {
		opacity: 1;
	}
}

.slider-gallery {
	.slider__image-wrapper {
		overflow: hidden;
		margin: 0 auto;
		max-width: 82%;
		@include breakpoint-down(small-desktop) {
			max-width: 100%;
		}
	}

	.slider__slide {
		box-shadow: 0 163px 78px -164px rgba(0 ,0 ,0,.2);
	}

	.slider__image {
		@include centerBackground;
		position: relative;
		padding-top: 56.1%;


		@include breakpoint-up(xxl-desktop) {
			max-width: 90%;
		}

		@include breakpoint-down(small-desktop) {
			padding-top: 62%;
		}

		@include breakpoint-down(mobile) {
			padding-top: 75%;
		}
	}

	.tns-slide-active {
		.slider__image {
			filter: blur(2px);

			&:before {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: #5f5d5b;
				transition: opacity .5s;
				opacity: .5;
			}
		}
	}

	.tns-slide-active + .tns-slide-active {
		.slider__image {
			filter: blur(0);

			&:before {
				opacity: 0;
			}
		}
	}

	.tns-slide-active + .tns-slide-active ~ .tns-slide-active {
		.slider__image {
			filter: blur(2px);

			&:before {
				opacity: .5;
			}
		}
	}
}
